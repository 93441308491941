import { Title } from '@solidjs/meta';
import { Container, Heading, Page, Picture, Section, TextLink } from '@troon/ui';
import { useLocation } from '@solidjs/router';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import type { RouteDefinition } from '@solidjs/router';

export default function ExperienceKapaluaSweepstakes() {
	const location = useLocation();

	return (
		<>
			<Title>Experience Kapalua Sweepstakes | Troon</Title>

			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/kapalua-hero.jpeg`}>
				<div class="flex flex-col items-center gap-4 py-12">
					<Heading as="h1">Experience Kapalua Sweepstakes</Heading>
					<p class="mb-8">Win a 4-day/3-night stay at The Ritz-Carlton Maui, Kapalua.</p>
				</div>
			</Hero>

			<Container size="small">
				<Page>
					<Section>
						<p>
							Kapalua Golf (home of The Sentry) and the award winning The Ritz-Carlton Maui, Kapalua are teaming up for
							the chance for you to win the ultimate Kapalua experience! Now you have the opportunity to win a truly
							remarkable Maui getaway to experience what many have as the #1 Hawaii golf and resort destination. By
							participating, you could be randomly selected to win a 4-day/3-night stay at The Ritz-Carlton Maui,
							Kapalua.
						</p>

						<Heading as="h2" size="h4">
							Your stay will include:
						</Heading>
						<ul class="flex list-disc flex-col gap-2 ps-4">
							<li>Three-night stay in a one-bedroom suite for two at The Ritz-Carlton Maui, Kapalua</li>
							<li>Access for two to The Ritz-Carlton Club Lounge</li>
							<li>Roundtrip Airport transportation</li>
							<li>Two tickets to the Tales of the Kapa Moe luau</li>
							<li>Two days of golf for two at Kapalua’s Plantation Course and/or Bay Course</li>
						</ul>

						<Picture
							src={`${getConfigValue('IMAGE_HOST')}/web/hero/kapalua-pool.jpeg`}
							alt="The Ritz-Carlton at Kapalua"
							width={720}
							height={420}
							sizes="(min-width: 1024px) 60vw, (min-width: 768px) 75vw, 95vw"
							class="w-full rounded"
						/>

						<Heading as="h2" size="h4">
							How to enter:
						</Heading>

						<p>
							The deadline to enter was Sunday, January 5, 2025, and the drawing for the winner took place on Monday Jan
							6, 2025. The winner will be notified via email.
						</p>

						<p>
							Please see the Kapalua/The Ritz-Carlton Maui, Kapalua Sweepstakes{' '}
							<TextLink href={`${location.pathname}/terms`}>Terms and Conditions</TextLink>.
						</p>
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;
